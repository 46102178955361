import axios from "axios";
import {
	useCallback
} from "react";
import {
	useAuthProvider
} from "../contexts/AuthProvider";
import {
	usersUrl
} from "../helpers/apiUrls";


const useUser = () => {

	const {
		token
	} = useAuthProvider();

	const getAll = useCallback(async ({
		limit,
		pageNumber,
		searchTerm
	}) => {
		const response = await axios.get(usersUrl, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params:{
				limit,
				pageNumber,
				searchTerm
			}
		});
		return response.data;
	}, [token]);

	const getById = useCallback(async (id) => {
		console.log(id)
		const response = await axios.get(`${usersUrl}/${id}`, {
			headers: {},
		});
		return response.data;
	}, []);


	const create = useCallback(async ({
		voornaam,
		familienaam,
		email,
		gsmnummer,
		takid
	}) => {
		const response = await axios({
			method: "POST",
			url: `${usersUrl}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {
				voornaam,
				familienaam,
				email,
				gsmnummer,
				takid
			}
		});

		return response.data;
	}, [token]);

	const update = useCallback(async (id, {
		voornaam,
		familienaam,
		email,
		gsmnummer,
		takid
	}) => {
		const response = await axios({
			method: "PUT",
			url: `${usersUrl}/${id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {
				voornaam,
				familienaam,
				email,
				gsmnummer,
				takid
			}
      
		});
		return response.data;
	}, [token]);

	const deleteUser = useCallback(async ({
		id
	}) => {

		const response = await axios({
			method: "DELETE",
			url: `${usersUrl}/${id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data;
	}, [token]);

	const uploadProfilePic = useCallback(async ({userid, formData}) => {

		const result = await axios.post(
			`${usersUrl}/${userid}/images`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data"
				}
			});
		return result;
	}, [token]);

	const getRoles = useCallback(async () => {
		const reponse = await axios({
			method: "GET",
			url: `${usersUrl}/rollen`
		});
		return reponse.data;
	});

	const setRoles = useCallback(async (userid, rollen) => {
		await axios({
			method: "POST",
			url: `${usersUrl}/${userid}/rollen`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {
				rollen
			}
		});
	},[token]);

	const getProfilePic = useCallback(async (id) => {
		const response = await axios({
			method: "GET",
			url: `${usersUrl}/${id}/images`,
			responseType: "blob"
		});
		return response.data;
	});

	const getUsersWithRole = useCallback(async (role) => {
		const response = await axios({
			method: "GET",
			url: `${usersUrl}?role=Groepsleiding`,
		});
		return response.data;
	})

	return {
		getAll,
		getById,
		create,
		uploadProfilePic,
		deleteUser,
		getProfilePic,
		update,
		getRoles,
		setRoles,
		getUsersWithRole
	};
};

export default useUser;