import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./components/homepage/Home";
import TakkenList from "./components/tak/TakkenList";
import { NotFound } from "./components/Errors/NotFound";
import "./App.css";
import "./AppBig.css";
import Navbar from "./components/Navbar";
import TakkenForm from "./components/tak/TakkenForm";
import TakDetail from "./components/tak/TakDetail";
import UserList from "./components/user/UserList";
import Kasacties from "./components/infopages/Kasacties";
import UserCreate from "./components/user/UserCreate";
import UserUpdate from "./components/user/UserUpdate";
import MediaViewer from "./components/images/MediaViewer";
import ImageList from "./components/images/ImageList";
import UploadImages from "./components/images/UploadImages";
import SGV from "./components/infopages/SGV";
import EventDetail from "./components/event/EventDetail";
import InfoPage from "./components/infopages/InfoPage";
import Login from "./components/authentication/Login";
import Profile from "./components/user/Profile";
import Logout from "./components/authentication/Logout";
import RequireAuth from "./components/authentication/RequireAuth";
import Verzekering from "./components/infopages/Verzekering";
import ScoutingOpMaat from "./components/infopages/ScoutingOpMaat";
import Privacy from "./components/infopages/Privacy";
import RequestWachtwoord from "./components/authentication/RequestWachtwoord";
import PasswordForgotten from "./components/authentication/PasswordForgotten";
import EventForm from "./components/event/EventForm";
import Gazette from "./components/gazette/Gazette";
import Inschrijvingen from "./components/infopages/Inschrijvingen";
import VerhuurForm from "./components/vzw/VerhuurForm";
import LogTable from "./components/logs/LogList";
import Contact from "./components/infopages/Contact";
import User from "./components/user/User";
import Inschrijven from "./components/inschrijvingen/Inschrijvingen";

function App() {

	const navigate = useNavigate();

	function RedirectToOldTijdloze() {
		navigate("http://oud.scoutswondelgem.be/tijdloze", { replace: true });
		return null;
	}

	return (
		<>
			<Navbar />
			<div className="body">
			<Routes>
				<Route index element={<Home />} />
				<Route path="takken" >
					<Route index element={<TakkenList />} />
					<Route path="create"  element={
						<TakkenForm />
					}/>
					<Route path=":naam"  element={<TakDetail />}/>
				</Route>

				<Route path="gazette" >
					<Route index element={<Gazette/>}/>
				</Route>
				<Route path="gebruikers" >
					<Route index element={
						<RequireAuth
							roles={["Admin", "Groepsleiding"]}>
							<UserList />
						</RequireAuth>
					}
					/>
					<Route path="add"  element={<UserCreate />}/>
					<Route path=":userid">
						<Route index element={<User></User>}/>
						<Route path="update" element={<UserUpdate />} />
					</Route> 
				</Route>
				<Route path="info" >
					<Route index  element={<InfoPage />}/>
					<Route path="inschrijvingen"  element={<Inschrijvingen />}/>
					<Route path="kasacties"  element={<Kasacties />}/>
					<Route path="scouts-en-gidsen-vlaanderen"  element={<SGV />}/>
					<Route path="privacybeleid" element={<Privacy />} />
					<Route path="verzekering" element={<Verzekering />} />
					<Route path="scouting-op-maat" element={<ScoutingOpMaat />} />
				</Route>
				<Route path="images" >
					<Route index  element={<MediaViewer />}/>
					<Route path=':takid'>
						<Route index  element={<MediaViewer />}/>
						<Route path=':mapid'>
							<Route index  element={<ImageList />}/>
							<Route path="upload" element={<UploadImages></UploadImages>} />
						</Route>
					</Route>
				</Route>
				<Route path='evenementen' >
					<Route path="create" element={<EventForm />} />
					<Route path=":id" element={<EventDetail />} />
				</Route>
				<Route path="profiel">
					<Route index element={<Profile></Profile>}/>
				</Route> 
				<Route path='auth'>
					<Route index element={<Login />} />
					<Route path='wachtwoord'>
						<Route index element={<RequestWachtwoord />} />
						<Route path='veranderen' element={<PasswordForgotten />} />
						<Route path='vergeten' element={<PasswordForgotten />} />
					</Route>
				</Route>
				<Route path='verhuur'>
					<Route index element={<VerhuurForm />} />
				</Route>

				<Route path='inschrijven'>
					<Route index element={<Inschrijven />} />
				</Route>

				<Route path="logs">
					<Route index element={<LogTable/>} />
				</Route>
				<Route path="tijdloze" element={<RedirectToOldTijdloze />} />
				<Route path="contact" element={<Contact />} />

				<Route path="logout" element={<Logout />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			</div>
		</>
	);
}

export default App;